.last-release-badge {
    font-size: 0.5rem;
    vertical-align: text-top;
    margin-left: 5px;
    padding: 4px;
}

.last-release-badge-tooltip {
    text-align: left;
    max-width: 400px;
}