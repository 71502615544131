// Header Base

.filter-navi-bar {
  position: fixed;
  top: 60px;
  z-index: 9;
  background: rgba(249, 200, 14, 0.85); //$primary;
  width: 100%;
  height: 1.5rem;
  box-shadow: $box-shadow-default;
  font-size: 0.8rem;
  padding-left: 1.5rem;
  line-height: 1.5rem;
  font-weight: bold;

  overflow: hidden;

  a {
    font-weight: normal;
  }
}

.app-header {
  height: $app-header-height;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all .2s;

  &.header-shadow {
    box-shadow: $box-shadow-default;
  }

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
    padding: 0 $layout-spacer-x;
    height: $app-header-height;

    .app-header-left {
      display: flex;
      align-items: center;
    }

    .app-header-right {
      align-items: center;
      display: flex;
      margin-left: auto;
    }
  }

  .header-user-info {
    & > .widget-heading,
    & > .widget-subheading {
      white-space: nowrap;
    }

    & > .widget-subheading {
      font-size: $font-size-xs;
    }
  }
}

.app-header__logo {
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  width: $app-sidebar-width;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  transition: width .2s;

  &.with-library {
    .lib-link {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      align-self: end;
      font-size: 0.8rem;
      text-align: center;
      width: 100%;
      border-top: 1px solid black;
    }
  }

  .logo-src {
    height: $logo-height;
    width: $logo-width;
    background: url('~assets/Service360-small.png');
  }
}

.app-header__logo.with-org .logo-src {
  overflow: hidden;
}

.app-header__logo.with-org .org-link {
  padding-left: 0.5rem;
  border-left: 2px solid black;
  text-transform: uppercase;
  font-size: $font-size-sm;
  margin: 0.3rem 0 0 0.5rem;
  font-weight: bold;
  color: $primary;
  white-space: nowrap;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
}

.app-header__menu,
.app-header__mobile-menu {
  display: none;
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  align-items: center;
}

// Header Modifiers

@import "modifiers/fixed-header";
@import "modifiers/header-dots";
@import "modifiers/header-megamenu";
@import "modifiers/header-buttons";
//@import "modifiers/header-horizontal";

// Header Themes

@import "themes/header-light";
@import "themes/header-dark";
//@import "themes/header-inverted";