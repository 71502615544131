.last-releases-overview {

}

.last-releases-overview .widget-content-wrapper {
    align-items: initial;
}

.last-releases-overview .list-group-item:first-child {
    border-top: 0;
}

.last-releases-overview .list-group-item {
    padding-left: 0;
}