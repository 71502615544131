.risk-badge-tooltip {
    max-width: 350px;
    text-align: left;
}

.risk-badge-tooltip p {
    margin-bottom: 0.3rem;
}

.risk-badge-tooltip p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}